<template>
  <v-container class="pa-0" fluid>
    <v-row no-gutters>
      <v-col cols="12" md="6" lg="4">
        <UserInfo v-if="currentUser" :user="currentUser" />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="6" lg="4">
        <PasswordChangeForm />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import PasswordChangeForm from '../components/PasswordChangeForm';
import UserInfo from '../components/UserInfo';

export default {
  name: 'Profile',

  components: {
    UserInfo,
    PasswordChangeForm,
  },

  computed: mapState('auth', ['currentUser']),
};
</script>
