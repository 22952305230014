<template>
  <v-card flat>
    <v-card-title v-if="user.person.full_name">
      {{ user.person.full_name }}
    </v-card-title>

    <v-card-text>
      <v-row dense>
        <v-col v-if="user.email" cols="12">
          <p>
            <span class="text-subtitle-2">
              {{ $t('email') }}
            </span>
            <br />
            <a :href="`mailto:${user.email}`" class="link">
              {{ user.email }}
            </a>
          </p>
        </v-col>

        <v-col v-if="user.person.phone" cols="12">
          <p>
            <span class="text-subtitle-2">
              {{ $t('phone') }}
            </span>
            <br />
            <a :href="`tel:${user.person.phone}`" class="link">
              {{ user.person.phone }}
            </a>
          </p>
        </v-col>

        <v-col v-if="user.person.address" cols="12">
          <p>
            <span class="text-subtitle-2">
              {{ $t('address') }}
            </span>
            <br />
            <a
              :href="`https://maps.google.com/?q=${user.person.address}`"
              class="link"
              target="_blank"
            >
              {{ user.person.address }}
            </a>
          </p>
        </v-col>

        <v-col cols="12">
          <p>
            <span class="text-subtitle-2">
              {{ $t('role') }}
            </span>
            <br />
            <span>
              {{ $t(`user_roles.${user.role}`) }}
            </span>
          </p>
        </v-col>

        <v-col cols="12" sm="6">
          <p>
            <span class="text-subtitle-2">
              {{ $t('status') }}
            </span>
            <br />
            <span>
              {{ $t(`user_statuses.${user.status}`) }}
            </span>
          </p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'UserInfo',

  props: {
    user: Object,
  },
};
</script>
